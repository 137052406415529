




































import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import STextarea from '@/common/components/STextarea.vue';
import {defineComponent} from '@vue/composition-api';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default defineComponent({
  name: 'UOttawaPostLab2Discussion',
  components: {AiLoadingOverlay, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        part1: '',
        part2: '',
        part3: '',
        part4: '',
        part5: '',
        part6: '',
        part7: '',
        part8: '',
        part9: '',
        part10: '',
        language: 'en',
      },
      questions: [
        {
          en: '1) Did you achieve your objective?',
          fr: '1) Avez-vous atteint votre but?',
          inputName: 'part1',
        },
        {
          en: '2) Are there design flaws that affected your results?',
          fr: '2) Est-ce qu’il y a des défauts dans votre design qui ont pu affecter vos résultats?',
          inputName: 'part2',
        },
        {
          en: '3) If so, how did you counter these flaws?',
          fr: '3) Si oui, comment avez-vous compensé pour ces défauts?',
          inputName: 'part3',
        },
        {
          en: '4) If so, could you compensate for them?',
          fr: '4) Est-ce qu’il était possible de compenser pour ces défauts?',
          inputName: 'part4',
        },
        {
          en: '5) If so, how did you factor the flaws into your explanation of your results?',
          fr: '5) Si oui, comment avez-vous intégré ces défauts dans l’explication de vos résultats?',
          inputName: 'part5',
        },
        {
          en: '6) Is this a feasible method for the project?',
          fr: '6) Est-ce que la méthode que vous ayez choisie est réalisable pour ce projet?',
          inputName: 'part6',
        },
        {
          en: '7) Would a different salt have a better effect? If so, state the salt and explain why.',
          fr: '7) Est-ce qu’un autre sel aura donné un meilleur résultat? Si oui, expliquez quel sel et pourquoi.',
          inputName: 'part7',
        },
        {
          en: '8) What were your Safety/health/cost considerations?',
          fr: '8) Quelles ont été vos considérations du côté de sécurité/santé/dépenses?',
          inputName: 'part8',
        },
        {
          en: '9) Is there a better way to do the experiment?',
          fr: '9) Est-ce qu’il y a une meilleure manière d’effectuer l’expérience?',
          inputName: 'part9',
        },
        {
          en: '10) Any other reasonable points may be discussed.',
          fr: '10) Tous autres points raisonnables pourraient être discutés.',
          inputName: 'part10',
        },
      ],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
});
